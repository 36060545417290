import React from 'react'
import 'twin.macro'

export default function Metrics() {
    return (
        <div tw="relative bg-gray-100 w-full">
          <div tw="h-80 absolute bottom-0 xl:inset-0 xl:h-auto xl:grid xl:grid-cols-2">
            <div tw="h-full xl:relative xl:col-start-2">
              <img tw="h-full w-full object-cover xl:absolute xl:inset-0" src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80" alt="People working on laptops" />
            </div>
          </div>
          <div tw="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8 mb-12">
            <div tw="relative pt-12 pb-80 sm:pt-24 xl:col-start-1 xl:pb-24">
              <h2 tw="text-sm font-semibold text-secondary-900 tracking-wide uppercase">Valuable Metrics</h2>
              <p tw="mt-3 text-3xl font-extrabold text-black">WHY CHOOSE US</p>
              <p tw="mt-5 text-lg text-gray-900">Our passion is people, not paychecks.  We strive to educate and create client-friendly products that keep you in control.</p>
              <div tw="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
                <p>
                  <span tw="block text-2xl font-bold text-black">Experience</span>
                  <span tw="mt-1 block text-base text-gray-900"><span tw="font-medium text-black">Over 18 years in the industry, & combined experience of over 42 years.</span></span>
                </p>

                <p>
                  <span tw="block text-2xl font-bold text-black">Personal</span>
                  <span tw="mt-1 block text-base text-gray-900"><span tw="font-medium text-black">You are not a number here. We work together every step of the way.</span></span>
                </p>

                <p>
                  <span tw="block text-2xl font-bold text-black">Compatability</span>
                  <span tw="mt-1 block text-base text-gray-900"><span tw="font-medium text-black">We make sure we’re a good fit before getting started.</span></span>
                </p>

                <p>
                  <span tw="block text-2xl font-bold text-black">Made with Love</span>
                  <span tw="mt-1 block text-base text-gray-900"><span tw="font-medium text-black">We ♥ what we do and hope that shows in every project we do.</span></span>
                </p>
              </div>
            </div>
          </div>
        </div>

    )
}
